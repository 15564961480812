
import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import SortingButtons from '@/modules/common/components/ui-kit/sorting-buttons.vue';
import STATISTIC_NAMES from '@/modules/deep-analysis/constants/statistic-names.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import TotalIndicatorData from '@/modules/deep-analysis/interfaces/total-indicator.interface';
import Pagination from '@/modules/common/components/ui-kit/pagination.vue';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../../../cluster-deep-analysis.service';
import TotalIndicator from './deep-analysis-total-indicator.vue';
import Skeleton from '../../_common/hotels-list/skeleton.vue';
import HotelLine from './hotel-line.vue';

@Component({
    components: {
        TotalIndicator,
        SortingButtons,
        CustomTooltip,
        Pagination,
        Skeleton,
        HotelLine,
    },
})
export default class CompsetBenchmarkTable extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) dcaClusterService!: DeepCompsetAnalysisClusterService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    private readonly STATISTICS_WITH_SORTING = [STATISTIC_TYPE.REVENUE];
    hint: HTMLElement | null = null;
    hintData: Record<string, string | number> | null = null;

    totalStats = this.statistics.map(() => ({
        total: 0,
        noChange: 0,
        decrease: 0,
        increase: 0,
    })) as TotalIndicatorData[];

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get statistics() {
        return this.dcaClusterService.statistics;
    }

    get sortingType() {
        return this.dcaClusterService.sortingType;
    }

    get statisticNames() {
        return STATISTIC_NAMES;
    }

    get sortingKey() {
        return this.dcaClusterService.sortingKey;
    }

    get calculatedFields() {
        return this.dcaClusterService.calculatedFields;
    }

    get hotels() {
        const { hotels } = this.dcaClusterService;

        if (!hotels || !hotels.length) {
            return [];
        }

        return hotels;
    }

    handleStatChange({ type, statIndex }: { type: 'inc' | 'dec' | 'no', statIndex: number }) {
        switch (type) {
            case 'inc':
                this.totalStats[statIndex].increase += 1;
                this.totalStats[statIndex].total += 1;
                break;
            case 'dec':
                this.totalStats[statIndex].decrease += 1;
                this.totalStats[statIndex].total += 1;
                break;
            case 'no':
                this.totalStats[statIndex].noChange += 1;
                this.totalStats[statIndex].total += 1;
                break;
            default:
                break;
        }
    }

    isSortingEnabled(statistic: STATISTIC_TYPE) {
        return this.STATISTICS_WITH_SORTING.includes(statistic);
    }

    handleHover(e: MouseEvent | UIEvent, totalData?: TotalIndicatorData) {
        if (!totalData) {
            this.hint = null;
            return;
        }

        this.hint = e.target as HTMLElement;
        this.hintData = {
            header: totalData.name,
            increased: totalData.increase,
            decreased: totalData.decrease,
            noChange: totalData.noChange,
        };
    }

    sort(e: {
        key: STATISTIC_TYPE,
        type: -1 | 1
    }) {
        this.dcaClusterService.sort(e.type, e.key);
    }
}
