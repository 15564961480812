
import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import DeepAnalysisClusterTable from '../components/deep-analysis/da-hotels-list/index.vue';
import DeepAnalysisHeader from '../components/deep-analysis/da-cluster-header.vue';
import DeepAnalysisActions from '../components/deep-analysis/da-cluster-actions.vue';
import DeepAnalysisClusterInfo from '../components/deep-analysis/da-cluster-info-text.vue';
import ClusterPagination from '../components/_common/cluster-pagination.vue';
import ClusterService, { ClusterServiceS } from '../cluster.service';

@Component({
    components: {
        PageWrapper,
        DeepAnalysisHeader,
        DeepAnalysisActions,
        DeepAnalysisClusterInfo,
        DeepAnalysisClusterTable,
        ClusterPagination,
    },
})
export default class DeepAnalysisPage extends Vue {
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    destroyed() {
        this.clusterService.resetLoading();
    }
}
